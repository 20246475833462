import React from 'react';
import Select from '@paljs/ui/Select';

export default function SelectCollaboratorPreloaded({ onChange, value, isMulti, orderCollaborators }) {
  const [internalValue, setInternalValue] = React.useState();

  React.useEffect(() => {
    if (value) {
      setInternalValue({value, label: value.name});
    } else {
      setInternalValue(null);
    }
  }, [value, onChange]);
  const optionsCollaborator = orderCollaborators?.length
      ? orderCollaborators.map((collaborator) => ({ value: collaborator, label: collaborator.name }))
      : [];
  
  return (
    <React.Fragment>
      <Select options={optionsCollaborator} isMulti={isMulti} isSearchable placeholder="Selecione um ou mais colaboradores" isLoading={false} onChange={onChange} value={internalValue} />
    </React.Fragment>
  );
}