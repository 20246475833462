import moment from "moment";
import { Checkbox } from "@paljs/ui";
import TextSpan from "../Text/TextSpan";
import {
  Table as TableSR,
  Thead as TheadSR,
  Tbody as TbodySR,
  Tr as TrSR,
  Th as ThSR,
  Td as TdSR,
} from "react-super-responsive-table";

export default function CollaboratorsTable({ collaborators, onNewCollaborator }) {
  if (!collaborators?.length) return <></>;

  return (
    <TableSR>
      <TheadSR>
        <TrSR>
          <ThSR textAlign="center" key={`col-Zw-1`}>
            <TextSpan apparence='p2' hint>
              Novo?
            </TextSpan>
          </ThSR>
          <ThSR textAlign="center" key={`col-Zw-1`}>
            <TextSpan apparence='p2' hint>
              Nome
            </TextSpan>
          </ThSR>
          <ThSR textAlign="center" key={`col-Zw-1`}>
            <TextSpan apparence='p2' hint>
              Função
            </TextSpan>
          </ThSR>
          <ThSR textAlign="center" key={`col-Zw-1`}>
            <TextSpan apparence='p2' hint>
              Codigo do Colaborador
            </TextSpan>
          </ThSR>
          <ThSR textAlign="center" key={`col-Zw-1`}>
            <TextSpan apparence='p2' hint>
              Data Válidade ASO
            </TextSpan>
          </ThSR>
        </TrSR>
      </TheadSR>
      <TbodySR>
        {collaborators?.map((collaborator, index) => (
          <TrSR key={index} isEvenColor={index % 2 === 0}>
            <TdSR textAlign="center">
              <Checkbox
                style={{ marginLeft: "0.5rem" }}
                checked={collaborator?.new}
                onChange={(value) => onNewCollaborator(value, index)} />
            </TdSR>
            <TdSR textAlign="center">
              <TextSpan apparence="s2">
                {collaborator?.name}
              </TextSpan>
            </TdSR>
            <TdSR textAlign="center">
              <TextSpan apparence="s2">
                {collaborator?.role}
              </TextSpan>
            </TdSR>
            <TdSR textAlign="center">
              <TextSpan apparence="s2">
                {collaborator?.suricataCollaboratorCode}
              </TextSpan>
            </TdSR>
            <TdSR textAlign="center">
              <TextSpan apparence="s2">
                {collaborator?.AsoExpirationDate ? moment(collaborator?.AsoExpirationDate).format("DD MMM YYYY") : "-"}
              </TextSpan>
            </TdSR>
          </TrSR>
        ))}
      </TbodySR>
    </TableSR>
  )
}
