import React from "react";
import styled, { css } from "styled-components";
import Dropzone from "react-dropzone";
import { DropInputIconFile } from "../Dropzone";
import Row from "@paljs/ui/Row";
import { CardUploadFile } from "../Cards/Upload";
import Col from "@paljs/ui/Col";
import { toast } from "react-toastify";
import { formatterMbKb } from "../Utils";

const DropContainer = styled.div`
  ${({ theme }) => css`
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: ${theme.borderBasicColor4};
      border-style: dashed;
      background-color: ${theme.backgroundBasicColor2};
      color: ${theme.textHintColor};
      outline: none;
      transition: border 0.24s ease-in-out;
    }

    .dropzone:focus {
      border-color: #2196f3;
    }

    .drop-image {
      justify-content: center;
      border-radius: 12px;
    }
  `}
`;

const maxSizeFile = process.env.REACT_APP_MAX_SIZE_FILE_BYTES;

function UploadFileDisableInput({ onAddFiles, files, onRemoveFile, disableInput = false, colMd = 2, accept = "" }) {

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length) {
            const fileMoreSize = acceptedFiles.find((x) => x.size > maxSizeFile);
            if (!!fileMoreSize) {
                toast.warn(
                    `O arquivo "${fileMoreSize.name}" excedeu o máximo permitido de ${formatterMbKb(maxSizeFile)}`
                );
                return;
            }
            const filesToAdd = files.concat(acceptedFiles);
            const totalSize = filesToAdd.reduce((a, b) => a + b.size, 0);
            if (totalSize > maxSizeFile) {
                toast.warn(
                    `A soma dos arquivos ultrapassam o máximo permitido de ${totalSize}. Remova um arquivo ou adicione um arquivo menor`
                );
                return;
            }

            onAddFiles(filesToAdd);
        }
    };

    return (
        <>
            <DropContainer>
                <Dropzone multiple onDrop={onDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="mb-3">
                            {!disableInput &&
                                <DropInputIconFile
                                    getRootProps={getRootProps}
                                    getInputProps={getInputProps}
                                />}
                            {!!files?.length && (
                                <Row className="mt-4">
                                    {files.map((file, i) => (
                                        <Col key={i} breakPoint={{ md: colMd }}>
                                            <CardUploadFile
                                                file={file}
                                                onRemoveFile={() => onRemoveFile(i)}
                                                isLoading={false}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </div>
                    )}
                </Dropzone>
            </DropContainer>
        </>
    );
}

export default UploadFileDisableInput;
