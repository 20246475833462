import React, { createContext, useState, useContext } from "react";
import * as auth from "../../services/AuthService";
import Fetch from "../Fetch";

const AuthContext = createContext({});

const getUser = () => {
  try {
    const storagedUser = localStorage.getItem("user");
    return JSON.parse(storagedUser)
  }
  catch {
    return null
  }
}

const getUserRole = async () => {
  try {
    const response = await Fetch.get("/user/user-role");
    return response.data
  } catch {
    return null;
  }

}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getUser());
  const [isSigned, setIsSigned] = useState(!!localStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(false);

  async function signIn({ email, password }, callback) {
    setIsLoading(true);
    const response = await auth.signIn({ email, password });

    if (!response) {
      setIsLoading(false);
      setIsSigned(false);
      return;
    }
    localStorage.setItem("token", response.token);
    setUser(response.user);
    setIsSigned(true);
    setIsLoading(false);
    if (callback)
      callback(response.user);
  }

  function signOut() {

    localStorage.clear();
    setUser(null);
    setIsSigned(false);
  }

  return (
    <>
      <AuthContext.Provider
        value={{ isSigned, user, isLoading, signIn, signOut }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth, getUserRole };
