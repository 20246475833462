import React from "react";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import Col from "@paljs/ui/Col";
import { Card, CardBody } from "@paljs/ui/Card";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingCard } from "../components/Loading/LoadingCard";
import TextSpan from "../components/Text/TextSpan";
import Fetch from "../components/Fetch";
import { toast } from "react-toastify";
import { useAuth } from "../components/Contexts/Auth";
import { useNavigate, Link } from "react-router-dom";

const Input = styled(InputGroup)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 100px;
  margin-left: -10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: column;
  justifiy-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Login = (props) => {
  const recaptchRef = React.useRef();

  const [reCaptcha, setReCaptcha] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const { signIn, isLoading, isSigned, locked } = useAuth();
  let navigate = useNavigate();

  React.useEffect(() => {
    localStorage.setItem('token', '')
  }, [])

  const onSetReCaptcha = async (reCaptcha) => {
    try {
      const response = await Fetch.post("/auth/recaptcha", { reCaptcha });
      if (response.data.valid) {
        setReCaptcha(true);
      } else {
        toast.warn("Erro ao validar reCaptcha");
      }
    } catch {
      toast.error("Erro ao validar reCaptcha")
    }
  }

  const onEnter = () => {
    if (!email?.trim()) {
      toast.warn("Informe o email");
      return;
    }
    if (!password?.trim()) {
      toast.warn("Informe a senha");
      return;
    }
    if (!reCaptcha) {
      toast.warn("Marque que você não é um robô");
      return;
    }
    signIn({ email: email?.trim(), password: password?.trim() },
      (user) => {
        navigate("/os-panel");
      })
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center" style={{ height: "100vh" }}>
        <Col breakPoint={{ md: 4, lg: 4, sm: 12 }} style={{ marginBottom: "300px" }}>
          <LoadingCard isLoading={isLoading}>
            <Card>
              <CardBody>
                <CenterRow>
                  <Image
                    src={'https://siot-file.konztec.com/enterprise/2022/01/07/20220107_205142_dca2a1ac96df4e9ea0d2c918a28be431.png'}
                    alt="Logo_Fas"
                  />
                </CenterRow>
                <Input fullWidth>
                  <input
                    type="email"
                    placeholder="Email"
                    disabled={reCaptcha}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Input>
                {reCaptcha ? <Input fullWidth>
                  <input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Input>
                  : <></>}

                {!reCaptcha ? <CenterRow>
                  <Input fullWidth>
                    <ReCAPTCHA
                      onChange={(value) => onSetReCaptcha(value)}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={recaptchRef}
                    />
                  </Input>
                </CenterRow>
                  : <></>}

                {reCaptcha ? <>
                  <CenterRow center className="mt-4">
                    <Link to="/request-password">
                      <TextSpan apparence="s2">
                        Primeiro acesso / Esqueci minha senha
                      </TextSpan>
                    </Link>
                  </CenterRow>
                </> : <></>}

                <Button
                  status="Success"
                  type="button"
                  shape="SemiRound"
                  disabled={isLoading || !password || !email}
                  fullWidth
                  onClick={onEnter}
                >
                  Acessar
                </Button>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 10 }}>
                  <TextSpan apparence="s2" hint>
                    2024 © IoTLog powered <a style={{ color: 'inherit', fontWeight: 'bold' }} href="https://www.bykonz.com" target="_blank">Bykonz</a>
                  </TextSpan>
                </div>
              </CardBody>
            </Card>
          </LoadingCard>
        </Col>
      </div>
    </>
  );
};
export default Login;
