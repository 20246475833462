import { SkeletonThemed } from "../Skeleton";
import { TD, TR } from "./index.jsx";

export default function LoadingRows() {
  return (
    <>
      <TR isEvenColor>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
      </TR>

      <TR>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
      </TR>
      <TR isEvenColor>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
        <TD>
          <SkeletonThemed />
        </TD>
      </TR>
    </>
  );
}
