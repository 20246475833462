import Col from "@paljs/ui/Col";
import Row from "@paljs/ui/Row";
import styled from "styled-components";
import { InputGroup } from "@paljs/ui/Input";
import { Button } from "@paljs/ui/Button";
import { EvaIcon } from "@paljs/ui/Icon"
import LabelIcon from "../Label/LabelIcon";
import DeleteConfirmation from "../DeleteConfirm/DeleteConfirmation"

const ColStyle = styled(Col)`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const RowStyle = styled(Row)`
  display: flex;
  width: 100%;
  flex-direction: row;
`

const RefusalReasonField = ({ onChange, onRefuse, onCancel }) => {
    return (
        <ColStyle breakPoint={{ lg: 12, md: 12 }} className="mb-4">
            <LabelIcon
                title="Motivo da recusa"
            />
            <div className="flex-row mb-4 mt-2" style={{ alignItems: "center" }}>
                <RowStyle>
                    <ColStyle breakPoint={{ lg: 11, md: 11 }} className="mb-4">
                        <InputGroup fullWidth>
                            <textarea
                                type="text"
                                placeholder="Motivo da recusa"
                                rows={3}
                                onChange={(text) => onChange(text.target.value)}
                            />
                        </InputGroup>
                    </ColStyle>
                    <ColStyle breakPoint={{ lg: 1, md: 1 }} style={{ alignItems: "center", justifyContent: "center" }} className="mb-4">
                        <div className="ml-4">
                            <DeleteConfirmation
                                onConfirmation={onRefuse}
                                onCancel={onCancel}
                                message="Deseja realmente recusar essa Ordem de Serviço?"
                            >
                                <Button className="flex-between"
                                    status="Danger"
                                    size="Tiny"
                                >
                                    <EvaIcon name="file-remove-outline" className="mr-1" />
                                    Recusar
                                </Button>
                            </DeleteConfirmation>
                            <Button className="mt-3 flex-between"
                                status="Basic"
                                size="Tiny"
                                appearance="ghost"
                                onClick={() => onCancel()}>
                                <EvaIcon name="close-outline" className="mr-1" />
                                Cancelar
                            </Button>
                        </div>
                    </ColStyle>
                </RowStyle>
            </div>
        </ColStyle >
    )
}

export default RefusalReasonField;
