import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import themes from "./themes";
import SimpleLayout from "./layouts/SimpleLayout";
import icons from "@paljs/icons";
import { Layout } from "@paljs/ui/Layout";
import { ToastContainer } from "react-toastify";
import SettingsContext, { SettingsProvider } from "./components/Settings";
import RouteMap from "./routes";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const Initial = (props) => {
  const { settings } = React.useContext(SettingsContext);

  return (
    <ThemeProvider theme={themes(settings.theme)}>
      <SimpleLayout />
      <Layout evaIcons={icons}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
          <RouteMap />
        </BrowserRouter>
        <ToastContainer />
      </Layout>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <SettingsProvider>
      <Initial />
    </SettingsProvider>
  );
};

export default App;
