import React from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader, CardFooter } from "@paljs/ui/Card";
import Col from "@paljs/ui/Col";
import Row from "@paljs/ui/Row";
import TextSpan from "../Text/TextSpan";
import { Button } from "@paljs/ui/Button";
import { EvaIcon } from "@paljs/ui/Icon";

const ModalContainer = styled.div`
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.25s ease;
  opacity: 1;
  visibility: visible;
  z-index: 9000;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function Modal({
  show,
  title,
  onClose,
  hideOnBlur = false, // the onClose function must exist
  accent = "Basic",
  children,
  renderFooter = undefined,
  size = "Medium",
  styleContent = undefined,
  subtitle = ""
}) {
  const getSize = () => {
    switch (size) {
      case "Small":
        return 4;
      case "Medium":
        return 6;
      case "Large":
        return 8;
      case "ExtraLarge":
        return 11;
      default:
        return 6;
    }
  };

  return (
    <>
      {show && (
        <ModalContainer onClick={() => { if (hideOnBlur && onClose) onClose(); }}>
          <Content>
            <Col breakPoint={{ md: getSize(), sm: 10 }} onClick={(e) => e.stopPropagation()}>
              <Card style={{ marginBottom: 0 }} accent={accent}>
                <CardHeader>
                  <Row between="xs" style={{ display: 'flex', flexWrap: 'nowrap' }} className="ml-1 mr-1">
                    <Col style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                      <TextSpan apparence="s1">
                        {title}
                      </TextSpan>
                      {subtitle && <TextSpan apparence="c2" hint>{subtitle}</TextSpan>}
                    </Col>
                    <Button
                      status="Danger"
                      size="Tiny"
                      appearance="ghost"
                      onClick={onClose}
                    >
                      <EvaIcon name="close-outline" />
                    </Button>
                  </Row>
                </CardHeader>
                <CardBody style={styleContent}>{children}</CardBody>
                {!!renderFooter && renderFooter()}
              </Card>
            </Col>
          </Content>
        </ModalContainer>
      )}
    </>
  );
}
