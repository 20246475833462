import * as React from "react";
import styled from "styled-components";
import { Card, CardHeader, CardBody, CardFooter } from "@paljs/ui/Card";
import Col from "@paljs/ui/Col";
import Row from "@paljs/ui/Row";
import { Button } from "@paljs/ui/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingCard } from "../components/Loading/LoadingCard";
import SelectCollaborator from "../components/Select/SelectCollaborator";
import Fetch from "../components/Fetch";
import CollaboratorsTable from "../components/Table/CollaboratorTable";
import TextSpan from "../components/Text/TextSpan";
import moment from "moment";
import { EvaIcon } from "@paljs/ui/Icon";
import { toast } from "react-toastify";

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
`

const AddCollaborators = (props) => {

  const [data, setData] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('id');
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [collaborators, setCollaborators] = React.useState([]);

  React.useLayoutEffect(() => {
    getData()

  }, [])
  const setNewCollaborator = (value, index) => {
    collaborators[index].value.new = value;
    setCollaborators([...collaborators])
  }
  const getData = () => {
    if (!orderId) {
      return;
    }
    setIsLoading(true);
    Fetch.get(`fas/orders/find?id=${orderId}`)
      .then(response => {
        setData(response.data ? response.data : [])
        if (response.data.collaborators) {
          setCollaborators(response.data.collaborators.map(col => {
            return {
              label: col.name,
              value: col,
              valid: col.valid,
              AsoExpirationDate: col.AsoExpirationDate
            }
          }))
        }
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  const onSave = async () => {


    if (!collaborators?.length) {
      toast.warn("Selecione ao menos um colaborador");
      return;
    }

    if (collaborators.some(collaborator => !collaborator.valid)) {
      toast.warn("Existem colaboradores com ASO vencido");
      return;
    }

    let result = collaborators.map(a => a.value);

    const body = {
      id: orderId,
      collaborators: result
    }

    setIsLoading(true);
    try {
      const response = await Fetch.post("/fas/add-collaborators", body);
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card style={{ height: "80vh" }}>
        <CardHeader>
          <Row middle="xs">
            <Button
              size="Small"
              className="mr-2 flex-between"
              status="Info"
              appearance="ghost"
              onClick={() => navigate(-1)}
            >
              <EvaIcon name="arrow-ios-back-outline" />
              Voltar
            </Button>
            Seleção de Colaboradores
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="mb-4 mt-4">
            <ColStyled breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                OS de Serviço
              </TextSpan>
              <TextSpan apparence="s2" className="pl-1">
                {data.name}
              </TextSpan>
            </ColStyled>
            <ColStyled breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Empresa:
              </TextSpan>

              <TextSpan apparence="s2" className="pl-1">
                {data.fasHeader?.enterprise?.name}
              </TextSpan>

            </ColStyled>
            <ColStyled breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Embarcação:
              </TextSpan>

              <TextSpan apparence="s2" className="pl-1">
                {data.fasHeader?.vessel?.name}
              </TextSpan>

            </ColStyled>
            <ColStyled breakPoint={{ lg: 3, md: 3 }}>
              <TextSpan apparence="p2" hint>
                Data do Atendimento:
              </TextSpan>

              <TextSpan apparence="s2" className="pl-1">
                {data.fasHeader?.serviceDate ? moment(data.fasHeader?.serviceDate).format("DD MMM YYYY HH:mm") : ""}
              </TextSpan>

            </ColStyled>
          </Row>
          <Row className="mb-4 mt-4">
            <ColStyled breakPoint={{ lg: 3, md: 3 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Local
              </TextSpan>
              <TextSpan apparence="s2" className="pl-1">
                {data.fasHeader?.local}
              </TextSpan>
            </ColStyled>
            {data.requestOrder ?
              <>
                <ColStyled breakPoint={{ lg: 6, md: 6 }} className="mb-4">
                  <TextSpan apparence="p2" hint>
                    Pedido/Requisição de Compra
                  </TextSpan>
                  <TextSpan apparence="s2" className="pl-1">
                    {data.requestOrder}
                  </TextSpan>
                </ColStyled>
              </> : <></>}
          </Row>
          <Row className="mb-4 mt-4">
            <ColStyled breakPoint={{ lg: 12, md: 12 }}>
              <TextSpan apparence="p2" hint>
                Descrição
              </TextSpan>
              <TextSpan apparence="s2" className="pl-1">
                {data.description}
              </TextSpan>
            </ColStyled>
          </Row>
          <Row>
            <ColStyled breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Código do Fornecedor
              </TextSpan>

              <TextSpan apparence="s2" className="pl-1">
                {data.supplierData?.codigoFornecedor}
              </TextSpan>

            </ColStyled>
            <ColStyled breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Razão Social do Fornecedor
              </TextSpan>

              <TextSpan apparence="s2" className="pl-1">
                {data.supplierData?.razao}
              </TextSpan>

            </ColStyled>
            <ColStyled breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Email do Fornecedor
              </TextSpan>

              <div className="pl-3">
                {data?.supplierData?.emails?.map((email) =>
                  <Row key={email} className="m-0">
                    <TextSpan className="pl-1" apparence="s1">
                      {email}
                    </TextSpan>
                  </Row>
                )}
              </div>

            </ColStyled>
          </Row>
          <Row>
            <ColStyled breakPoint={{ lg: 12, md: 12 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Seleção de Colaboradores *
              </TextSpan>
              <SelectCollaborator
                dataAtendimento={data?.fasHeader?.serviceDate}
                isMulti
                onChange={(selected) => setCollaborators(selected)}
                value={collaborators} />
              {!!collaborators?.length ? <TextSpan apparence="p2" className="mt-2 pl-1">
                * Atenção: os colaboradores aqui informados serão verificados e serão os únicos autorizados a entrar para realizar o serviço!
              </TextSpan> : <></>}
            </ColStyled>
          </Row>
          <Row style={{ margin: 0 }}>
            <CollaboratorsTable
              collaborators={collaborators.map(collaborator => collaborator.value)}
              onNewCollaborator={setNewCollaborator} />
          </Row>
        </CardBody>
        <CardFooter>
          <Row end="xs">
            <Button size="Tiny"
              className="flex-between mr-2"
              onClick={onSave}>
              <EvaIcon name="checkmark-outline" className="mr-1" />
              Salvar
            </Button>
          </Row>
        </CardFooter>
      </Card>
      <LoadingCard isLoading={isLoading} />
    </>
  )
}

export default AddCollaborators
