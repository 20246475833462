import styled, { css } from "styled-components";
import TextSpan from "../components/Text/TextSpan";

export const isInStatus = (value) => {
  return [
    "fas.closed",
    "bms.refused",
    "awaiting.request",
    "awaiting.collaborators",
    "awaiting.bms.confirm",
    "awaiting.bms",
    "awaiting.buy.request",
    "awaiting.payment",
    "awaiting.invoice",
    "awaiting.rating",
    "invoice.rejected"
  ].includes(value);
}

const Badge = styled.div`
  display: flex;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  ${({ status, theme, intensity = 500 }) => css`
    background-color: ${theme[`color${status}100`]};
    color: ${theme[`color${status}${intensity}`]};
    text-transform: uppercase;
  `}
  flex-wrap: nowrap;
    text-align: center;
    line-height: 0.8rem;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ end }) => end ? "flex-end" : "center"};
`

export default function StatusFas(props) {

  const { status, end = false, styleText = {} } = props;

  switch (status) {
    case "fas.closed":
      return (
        <Content end={end}>
          <Badge status="Basic" intensity={600}>
            <TextSpan apparence="s3" style={styleText}>
              OS Fechada
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.collaborators":
      return (
        <Content end={end}>
          <Badge status="Danger" intensity={500}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando Seleção de Colaboradores
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.bms.confirm":
      return (
        <Content end={end}>
          <Badge status="Danger" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando confirmação de BMS
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.sap":
      return (
        <Content end={end}>
          <Badge status="Warning" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando SAP
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.rating":
      return (
        <Content end={end}>
          <Badge status="Danger" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando Avaliação
            </TextSpan>
          </Badge>
        </Content>
      )
    case "bms.refused":
      return (
        <Content end={end}>
          <Badge status="Warning" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              BMS Recusada
            </TextSpan>
          </Badge>
        </Content>
      )
    case "invoice.rejected":
      return (
        <Content end={end}>
          <Badge status="Warning" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              Nota Fiscal Recusada
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.bms":
      return (
        <Content end={end}>
          <Badge status="Warning" intensity={600}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando BMS
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.payment":
      return (
        <Content end={end}>
          <Badge status="Success" intensity={500}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando Confirmação de Pagamento
            </TextSpan>
          </Badge>
        </Content>
      )
    case "awaiting.invoice":
      return (
        <Content end={end}>
          <Badge status="Info" intensity={700}>
            <TextSpan apparence="s3" style={styleText}>
              Aguardando Nota fiscal
            </TextSpan>
          </Badge>
        </Content>
      )
    default:
      return null
  }
}
