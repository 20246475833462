import React from "react";

export const DropInputIconFile = ({ getRootProps, getInputProps }) => (
  <React.Fragment>
    <div
      {...getRootProps({
        className: "dropzone drop-image pt-5",
        multiline: true,
      })}
    >
      <input {...getInputProps()} />
      <svg
        style={{ width: 50, height: 50 }}
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="file-plus"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        className="svg-inline--fa fa-file-plus fa-w-12 fa-3x"
      >
        <path
          fill="currentColor"
          d="M369.9,97.9,286,14A48,48,0,0,0,252.1-.1H48A48.16,48.16,0,0,0,0,48V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V131.9A48.23,48.23,0,0,0,369.9,97.9ZM256,51.9,332.1,128H256ZM336,464H48V48H208V152a23.94,23.94,0,0,0,24,24H336ZM215,223.75a16,16,0,0,0-16-16H183a16,16,0,0,0-16,16v56.5h-55.5a16,16,0,0,0-16,16v16a16,16,0,0,0,16,16H167v56a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16v-56h56.5a16,16,0,0,0,16-16v-16a16,16,0,0,0-16-16H215Z"
          className=""
        ></path>
      </svg>
      <span className="text" style={{ marginTop: 10, fontWeight: "600" }}>
        Arraste as fotos e arquivos aqui ou clique para selecionar
      </span>
    </div>
  </React.Fragment>
);
