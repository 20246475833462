import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/Contexts/RequireAuth";

import LayoutBasic from "./layouts/LayoutBasic";
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import OSPanel from './pages/OSPanel';
import { AuthProvider } from "./components/Contexts/Auth";
import OrderView from "./pages/OrderView";
import AddCollaborators from "./pages/AddCollaborators";
import AddBms from "./pages/AddBms";
import AddInvoice from "./pages/AddInvoice";
import RequestPassword from "./pages/RequestPassword";


const paths = [
  {
    path: "/",
    exact: true,
    layout: LayoutBasic,
    component: OSPanel,
  },
  {
    path: "/os-panel",
    layout: LayoutBasic,
    component: OSPanel,
  },
  {
    path:"/order-view",
    layout: LayoutBasic,
    component: OrderView
  },
  {
    path:"/add-collaborators",
    layout: LayoutBasic,
    component: AddCollaborators
  },
  {
    path:"/add-bms",
    layout: LayoutBasic,
    component: AddBms
  },
  {
    path:"/add-invoice",
    layout: LayoutBasic,
    component: AddInvoice
  },
];


export default function RouteMap(props) {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/request-password" element={<RequestPassword />} />
          {/* <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} /> */}

          {paths.map((route, index) => {
            return (<Route
              key={`${index}${route.path}`}
              path={route.path}
              exact={route.exact}
              element={
                <RequireAuth key={`${index}${route.path}`}>
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                </RequireAuth>
              }
            />
            )
          })}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </>
  )
}


