import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, EvaIcon } from "@paljs/ui";
import { Card, CardBody, CardHeader, CardFooter } from "@paljs/ui";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { InputGroup } from "@paljs/ui/Input";
import TextSpan from "../components/Text/TextSpan";
import Fetch from "../components/Fetch";
import { toast } from "react-toastify";
import UploadFileDisableInput from "../components/UploadFile"

const maxSizeFile = process.env.REACT_APP_MAX_SIZE_FILE_BYTES;

const AddInvoice = (props) => {

  const [data, setData] = React.useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('id');

  const [files, setFiles] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useLayoutEffect(() => {
    getData()
  }, [])

  const getData = () => {
    if (!orderId) {
      return;
    }
    setIsLoading(true);
    Fetch.get(`fas/orders/find?id=${orderId}`)
      .then(response => {
        setData(response.data ? response.data : [])
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
      })
  }

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const fileMoreSize = acceptedFiles.find((x) => x.size > maxSizeFile);
      if (!!fileMoreSize) {
        toast.warn("Arquivo Muito Grande");
        return;
      }
      const filesToAdd = acceptedFiles;
      const totalSize = filesToAdd.reduce((a, b) => a + b.size, 0);
      if (totalSize > maxSizeFile) {
        toast.warn("Tamanho Máximo de Arquivos Excedido");
        return;
      }

      setFiles(filesToAdd);
    }
  };

  const onRemoveFile = (index) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  };

  const onSave = async () => {

    setIsLoading(true);
    try {
      if (files?.length) {
        for await (let fileToSave of files) {
          const data = new FormData();
          data.append("id", orderId);
          data.append("file", fileToSave);
          await Fetch.post(
            `/invoice/add-invoice`,
            data
          );
        }
      } else {
        toast.warn("Adicione a Nota Fiscal");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row middle="xs">
            <Button
              size="Small"
              className="mr-2 flex-between"
              status="Info"
              appearance="ghost"
              onClick={() => navigate(-1)}
            >
              <EvaIcon name="arrow-ios-back-outline" />
              Voltar
            </Button>
            Adicione a Nota Fiscal
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                OS de Serviço
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2">
                  {data.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Empresa:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2">
                  {data.fasHeader?.enterprise?.name}
                </TextSpan>
              </InputGroup>
            </Col>
            <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Embarcação:
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2">
                  {data.fasHeader?.vessel?.name}
                </TextSpan>
              </InputGroup>
            </Col>

            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
              <TextSpan apparence="p2" hint>
                Descrição
              </TextSpan>
              <InputGroup fullWidth className="mt-1">
                <TextSpan apparence="s2" className="pl-1">
                  {data.description}
                </TextSpan>
              </InputGroup>
            </Col>

            {data.buyRequest ?
              <>
                <Col breakPoint={{ lg: 4, md: 4 }} className="mb-4">
                  <TextSpan apparence="p2" hint style={{ marginRight: "10px" }}>
                    Pedido de compra
                  </TextSpan>
                  <InputGroup fullWidth className="mt-1">
                    <TextSpan apparence="s2" className="pl-1">
                      {data.buyRequest}
                    </TextSpan>
                  </InputGroup>
                </Col>
              </> : <></>}
          </Row>
          <Row className="mb-4">
            <Col>
              <UploadFileDisableInput
                onAddFiles={onDrop}
                files={files}
                onRemoveFile={onRemoveFile}
                disableInput={files.length > 0}
                accept={{
                  'image/png': ["png"],
                  'image/jpeg': ["jpg", "jpeg"],
                  'application/pdf': ["pdf"],
                }}
              />
              <TextSpan apparence="p2" hint>
                *Apenas arquivos PDF/PNG/JPG/JPEG/MP4/MPEG/XLS/XLSX
              </TextSpan>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row end="xs">
            <Button size="Tiny"
              className="flex-between mr-2"
              disabled={isLoading || !files?.length}
              onClick={onSave}>
              <EvaIcon name="checkmark-outline" className="mr-1" />
              Salvar
            </Button>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}

export default AddInvoice;
