import React from "react";
import Spinner from '@paljs/ui/Spinner';

const LoadingCard = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className="col-flex-center h-100">
          <Spinner status="Primary" style={{ backgroundColor: 'transparent' }}/>
        </div>
      ) : !!children ? (
        children
      ) : (
        <div />
      )}
    </>
  );
};

export { LoadingCard };
