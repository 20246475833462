import Col from "@paljs/ui/Col";

const FasSpanOrderDescription = ({ breakPoint = { lg: 4, md: 4 }, apparence = "s1", title, text, titleText = "", ...rest }) => {

  return (
    <Col breakPoint={breakPoint} className={`mb-4 ${rest.className}`} {...rest} >
      <div style={{
        fontWeight: "600",
        fontSize: "0.9rem",
        whiteSpace: "pre-line"
      }} className="pl-1">
        {text}
      </div>
    </Col>
  );
}

export { FasSpanOrderDescription }
