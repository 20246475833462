import React from "react";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import TextSpan from "../Text/TextSpan";
import { TABLE, TBODY, TD, TH, THEAD, TR, TRH } from '../Table';
import LoadingRows from "../Table/LoadingRows";
import { floatToStringExtendDot } from "../Utils";

export default function BmsExpensesTable({ bmsData, isLoading = false, ...props }) {
    return (
        <>
            {
                bmsData?.main_expenses?.length ?
                    isLoading ?
                        <Row>
                            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                                <TextSpan apparence="p2" hint>
                                    BMS
                                </TextSpan>
                                <TABLE>
                                    <TBODY>
                                        <LoadingRows />
                                    </TBODY>
                                </TABLE>
                            </Col>
                        </Row>
                        :
                        <>
                            <TextSpan apparence="p2" hint>
                                BMS
                            </TextSpan>
                            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                                <TextSpan apparence="p2" hint>
                                    Despesas
                                </TextSpan>
                                <TABLE>
                                    <THEAD>
                                        <TRH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Data
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Despesa
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Horário de Início
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Horário de Término
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Nome do Colaborador
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Função
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Unidade
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Valor
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Quantidade
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="end">
                                                <TextSpan apparence='p2' hint>
                                                    Vl. Total
                                                </TextSpan>
                                            </TH>
                                        </TRH>
                                    </THEAD>
                                    <TBODY>
                                        {bmsData?.main_expenses?.map(({ _id, ...expense }, i) =>
                                            <TR key={i} isEvenColor={i % 2 === 0} >
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.date ? expense?.date : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.expense ? expense?.expense : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.startTime ? expense?.startTime : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.endTime ? expense?.endTime : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.collaborator_name ? expense?.collaborator_name : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.role ? expense?.role : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.unit ? expense?.unit : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.value ? `R$ ${floatToStringExtendDot(expense?.value)}` : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.amount ? expense?.amount : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.total ? `R$ ${floatToStringExtendDot(expense?.total)}` : ""}
                                                    </TextSpan>
                                                </TD>
                                            </TR>
                                        )}
                                    </TBODY >
                                </TABLE>
                            </Col>
                        </> : <></>
            }

            {
                bmsData?.other_expenses?.length ?
                    isLoading ?
                        <Row className="m-0">
                            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                                <TextSpan apparence="p2" hint>
                                    Outras Despesas
                                </TextSpan>
                                <TABLE>
                                    <TBODY>
                                        <LoadingRows />
                                    </TBODY>
                                </TABLE>
                            </Col>
                        </Row> :
                        <Row className="m-0">
                            <Col breakPoint={{ lg: 12, md: 12 }} className="mb-4">
                                <TextSpan apparence="p2" hint>
                                    Outras Despesas
                                </TextSpan>
                                <TABLE>
                                    <THEAD>
                                        <TRH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Data
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Despesa
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Horário de Início
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Horário de Término
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Nome do Colaborador
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Informação Adicional
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Unidade
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Valor
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="center" key={`col-zW-1`}>
                                                <TextSpan apparence="p2" hint>
                                                    Quantidade
                                                </TextSpan>
                                            </TH>
                                            <TH textAlign="end">
                                                <TextSpan apparence='p2' hint>
                                                    Vl. Total
                                                </TextSpan>
                                            </TH>
                                        </TRH>
                                    </THEAD>
                                    <TBODY>
                                        {bmsData?.other_expenses?.map(({ _id, ...expense }, i) =>
                                            <TR key={i} isEvenColor={i % 2 === 0} >
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.date ? expense?.date : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.expense ? expense?.expense : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.startTime ? expense?.startTime : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.endTime ? expense?.endTime : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.collaborator_name ? expense?.collaborator_name : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.additional_info ? expense?.additional_info : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.unit ? expense?.unit : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.value ? `R$ ${floatToStringExtendDot(expense?.value)}` : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.amount ? expense?.amount : ""}
                                                    </TextSpan>
                                                </TD>
                                                <TD textAlign="center">
                                                    <TextSpan apparence="s2" className="pl-1">
                                                        {expense?.total ? `R$ ${floatToStringExtendDot(expense?.total)}` : ""}
                                                    </TextSpan>
                                                </TD>
                                            </TR>
                                        )}
                                    </TBODY>
                                </TABLE>
                            </Col>
                        </Row>
                    : <></>
            }
        </>
    )
}