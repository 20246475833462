import React, { useState } from "react";

const SettingsContext = React.createContext();

const getFromStorage = () => {
   const saved = localStorage.getItem("settings");
   return !!saved
     ? JSON.parse(saved)
     : {
         theme: "default",
       };
 };

 const defaultSettings = getFromStorage();


export const SettingsProvider = ({ children, settings }) => {
  
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const saveSettings = (values) => {
    setCurrentSettings(values);
    localStorage.setItem("settings", JSON.stringify(values));
  };

  return (
    <SettingsContext.Provider
      value={{ settings: currentSettings, saveSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
