import React from "react";
import { floatToStringNormalize, parseToFloatValid } from "../Utils";

const parseValueSizeDecimal = (value, sizeDecimals) => {
  return sizeDecimals
    ? value.includes(",")
      ? `${value.split(",")[0]},${value.split(",")[1]?.slice(0, sizeDecimals)}`
      : value
    : value;
};

const InputDecimal = ({
  value,
  onChange,
  placeholder,
  sizeDecimals = 0,
  onBlur = () => {},
}) => {
  const [valueInterval, setValueInterval] = React.useState();

  React.useLayoutEffect(() => {
    setValueInterval(
      value !== null && value !== undefined ? floatToStringNormalize(value) : ""
    );
  }, [value]);

  const onChangeInternal = (value) => {
    if (!value) {
      onChange(null);
      return;
    }

    let subtract = 0;
    if (value?.includes("-")) {
      subtract++;
    }
    if (value?.includes(",")) {
      subtract++;
    }
    const hasThereLetterOrCharacterInvalid =
      value.replace(/\D/g, "")?.length !== value.length - subtract;
    const validSizeDecimals = !!(!sizeDecimals && value?.includes(","));

    if (
      (value.length === 1 && value === ",") ||
      hasThereLetterOrCharacterInvalid ||
      (validSizeDecimals && (value.length === 1 && value === ",")) ||
      value?.split(",")?.length > 2
    ) {
      return;
    }

    if (value?.indexOf("-") > 1) {
      return;
    }

    if (value?.slice(0) === "-" || (sizeDecimals && value?.slice(-1) === ",")) {
      setValueInterval(value);
      return;
    }

    const valueToParse = parseValueSizeDecimal(value, sizeDecimals);

    const parsed = parseToFloatValid(valueToParse);
    onChange(parsed);
    setValueInterval(valueToParse);
  };

  return (
    <>
      <input
        value={valueInterval}
        onChange={(e) => onChangeInternal(e.target.value)}
        type="text"
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </>
  );
};

export default InputDecimal;
