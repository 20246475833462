import React from "react";
import { Card, CardBody, CardFooter } from "@paljs/ui/Card";
import { Button } from "@paljs/ui/Button";
import Row from "@paljs/ui/Row";
import { EvaIcon } from "@paljs/ui/Icon";
import { formatterMbKb } from "../Utils";


const CardUploadFile = (props) => {
  const { file, onRemoveFile, download, deleteButton, onDownloadFile } = props;

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="col-flex-center mt-2">
            <EvaIcon name="file-text" status="Basic" />
          </div>
          <div className="col-flex-center mt-3">
            <span
            style={{ fontSize: 12, textAlign: 'center'}}
            className="ml-2 mr-2 text-file-name">{file.path}</span>
            <span style={{ fontSize: 9 }}>
              {formatterMbKb(file?.size)}
            </span>
          </div>
        </CardBody>
        <CardFooter>
          <Row
          className="m-0"
          style={{ display: 'flex', justifyContent: 'space-around' }}>

            {deleteButton?
            <>
              <Button
                status="Danger"
                size="Small"
                appearance="ghost"
                onClick={onRemoveFile}
              >
                <EvaIcon name="trash-2-outline" />
              </Button>
            </>: <></>}
            {download?
            <>
            <Button
              size="Tiny"
              onClick={onDownloadFile}
              status="Basic"
            >
              <EvaIcon name="download-outline" />
            </Button>
            </>: <></>}
          </Row>
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

export { CardUploadFile };
