export const formatterMbKb = (bytes) => {

  if (!bytes) return "0 B";
  if (bytes < 1024) return `${bytes} B`;
  const totalMB = (bytes / 1048576).toFixed(2);
  return totalMB > 0 ? `${totalMB} MB` : `${totalMB * 1024} KB`;
};

export const parseToFloatValid = (value) => {
  if (!value) return 0;
  const parsedFloat = parseFloat(value.toString().replace(",", "."));
  return isNaN(parsedFloat) ? 0 : parsedFloat;
};

export const floatToStringNormalize = (value) => {
  return !value && value !== 0 ? "" : value?.toString()?.replace(".", ",");
};

export const floatToStringExtendDot = (value, decimals = 2) => {
  const safeValue = Number(value)
  return (
    (safeValue || 0)
      ?.toFixed(decimals)
      ?.replace(".", ",")
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".") || "0,00"
  );
};
