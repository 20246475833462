import React from "react";
import { Button } from "@paljs/ui/Button";

const NotFound = (props) => {
  return (
    <>
      <div className="col-flex-center">
        <h2>Página não encontrada - 404</h2>
        <Button onClick={() => props.history.push("/")}>Voltar</Button>
      </div>
    </>
  );
}
export default NotFound;