import React from 'react';
import Select from '@paljs/ui/Select';
import Fetch from '../Fetch';


export default function SelectCollaborator({ onChange, value, isMulti, dataAtendimento }) {

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (dataAtendimento) {
      getData(dataAtendimento)
    }
  }, [dataAtendimento])

  const getData = (dataAtendimento) => {
    setIsLoading(true);
    Fetch.get(`fas/colaboradores?dataAtendimento=${dataAtendimento}`)
      .then(response => {
        setData(response.data ? response.data : [])
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  const optionsCollaborator =
    data && data?.length
      ? data.map((x) => ({
        value: x,
        label: x.name,
        valid: x.valid
      }))
      : [];

  return (
    <React.Fragment>
      <Select options={optionsCollaborator}
       isMulti={isMulti}
       isSearchable
       placeholder="Selecione um ou mais colaboradores"
       isLoading={isLoading}
       onChange={onChange}
       value={value}
       isOptionDisabled={(option) => option.value.valid !== true}
       menuPosition='fixed'
       menuPlacement='top'
      />
    </React.Fragment>
  );
}
