import React, { useState } from "react";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { toast } from "react-toastify";
import Fetch from "../components/Fetch";
import LabelIcon from "../components/Label/LabelIcon";
import { LoadingCard } from "../components/Loading/LoadingCard";
import TextSpan from "../components/Text/TextSpan";
import Row from "@paljs/ui/Row";
import { breakpointDown, Card, CardBody, EvaIcon } from "@paljs/ui";

const InputStyled = styled(InputGroup)`
  justify-content: center;
`;

const ContainerIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 10px;
`;

const CardAuth = styled(Card)`
  margin: 50px;
  height: calc(100vh - 7rem);
  ${breakpointDown("sm")`
    margin: 0;
    height: 100vh;
  `}
  ${CardBody} {
    display: flex;
  }
`;

const ContentAround = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const RequestPassword = (props) => {
  const recaptchRef = React.useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reCaptcha, setReCaptcha] = useState("");
  const [messageShow, setMessageShow] = useState(false);

  const requestClick = () => {
    setIsLoading(true);
    Fetch.post("/auth/generate-password", { email: email?.replace(/ /g, "") })
      .then((response) => {
        toast.success("Email enviado");
        setIsLoading(false);
        setMessageShow(true);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onSetReCaptcha = async (reCaptcha) => {
    try{
    const response = await Fetch.post("/auth/recaptcha", {reCaptcha});
      if (response.data.valid){
        setReCaptcha(true);
      } else {
        toast.warn("Erro ao validar reCaptcha");
      }
    } catch {
      toast.error("Erro ao validar reCaptcha")
    }
  }

  return (
    <Row center middle style={{ margin: 0 }}>
      <CardAuth>
        <CardBody>
          <ContentAround>
            <TextSpan apparence="s1">
              Solicitar nova senha
            </TextSpan>
            <TextSpan apparence="p2">
              Sera encaminhado um email contendo as instruções para a criação de uma nova senha.
            </TextSpan>

            <form style={{ width: "100%" }}>
              {!!messageShow ? (
                <Button
                  status="Success"
                  type="button"
                  shape="SemiRound"
                  appearance="outline"
                  fullWidth
                  onClick={() => navigate("/login")}
                >
                  Verifique seu email! Acabamos de enviar as instruções para você
                </Button>
              ) : (
                <>
                  <div>
                    <LabelIcon iconName="email-outline" title={"Email"} />
                    <InputGroup fullWidth>
                      <input
                        type="email"
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <ContainerIcon>
                        <EvaIcon name={"email-outline"} status="Basic" />
                      </ContainerIcon>
                    </InputGroup>
                  </div>
                  <InputStyled fullWidth className="mt-4 mb-4">
                    <ReCAPTCHA
                      onChange={(value) => onSetReCaptcha(value)}
                      size="normal"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={recaptchRef}
                    />
                  </InputStyled>
                  <Button
                    status="Success"
                    type="button"
                    shape="SemiRound"
                    disabled={!email || !reCaptcha || !!messageShow}
                    fullWidth
                    onClick={requestClick}
                  >
                    Continuar
                  </Button>
                </>
              )}
            </form>
            <Row center className="mt-4">
              <Link to="/login">
                <TextSpan apparence="s2">
                  Voltar ao login
                </TextSpan>
              </Link>
            </Row>
          </ContentAround>
        </CardBody>
      </CardAuth>
      <LoadingCard isLoading={isLoading} />
    </Row>
  );
};

export default RequestPassword;
