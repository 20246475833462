import jwt_decode from "jwt-decode";
import Fetch from "../components/Fetch";


export async function signIn({ email, password }) {
  try {
    const response = await Fetch.post("/auth/login", { email: email ? email.replace(/ /g, "") : "", password });
    if (!response.data?.token) {
      return
    }

    const userData = jwt_decode(response.data.token);
    return {
      token: response.data?.token,
      user: userData,
    };
  } catch (error) {
    return;
  }
}


